<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
      'My Contracts': 'Meus Contratos',
    },
    es: {
      'My Contracts': 'Mis Contratos',
    }
  },
  components: {
    Layout,
  },
  data() {
    return {
      loading: true,
      contracts: [],
      total: {
        pending: 0,
        inactive: 0,
        active: 0,
        expired: 0,
      },

      filter: {
        year: '2024',
      }
    };
  },
  methods: {
    getList() {
      api
        .get('contract')
        .then((response) => {
          if (response.data.status == 'success') {
            this.contracts = response.data.list
            this.total = response.data.total
          }

          this.loading = false
        })
    }
  },
  mounted() {
    this.getList()
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('My Contracts') }}</h4>
        </div>
      </div>
    </div>
    <div v-if="loading" class="text-center">
      <b-spinner small class="ml-2 align-middle" variant="dark" role="status"></b-spinner>
    </div>
    <div v-else-if="!contracts || contracts && contracts.length == 0" class="card">
      <div class="card-body">
        <div class="text-center py-5">
          <i class="far fa-sad-cry font-size-24"></i><br>
          você ainda não possui um contrato
        </div>
      </div>
    </div>
    <div v-else>
      <div class="card contract text-dark">
        <div class="card-body pb-0 row">
          <div class="contract-total col-md-3 col-lg-3 pb-3 pr-4 mr-4" v-for="(value, index) in total" :key="index">
            <div v-if="index == 'pending'">
              {{ value | currency }}<br>
              <div class="font-size-11 text-uppercase">Pendente</div>
            </div>
            <div v-else-if="index == 'inactive'">
              {{ value | currency }}<br>
              <div class="font-size-11 text-uppercase">Inativo</div>
            </div>
            <div v-else-if="index == 'active'">
              {{ value | currency }}<br>
              <div class="font-size-11 text-uppercase">Ativo</div>
            </div>
            <div v-else-if="index == 'expired'">
              {{ value | currency }}<br>
              <div class="font-size-11 text-uppercase">Finalizado</div>
            </div>
          </div>
        </div>
      </div>
      <div class="divisor mb-3">
        <span>{{ t('CONTRATOS') }}</span>
      </div>
      <div class="row">
        <div class="col-md-6" v-for="(con,index) in contracts" :key="index">
          <router-link :to="'/contracts/'+con.id">
            <div class="card contract text-dark">
              <div class="card-body pb-2">
                <div class="row mb-2">
                  <div class="col-md-4 contract-code mb-2">
                    <small>CÓDIGO</small><br>
                    #{{ con.code }}
                  </div>
                </div>
                <div class="border-top pt-3 row">
                  <div class="col-md-4 contract-code mb-2">
                    <small>VALOR</small><br>
                    {{ con.value | currency }}
                  </div>
                  <div class="col-md-4 contract-status mb-2">
                    <small>STATUS</small><br>
                    <span v-if="con.status == 'pending'" class="badge badge-danger p-2 font-size-13 text-uppercase">Pendente</span>
                    <span v-else-if="con.status == 'inactive'" class="badge badge-primary p-2 font-size-13 text-uppercase">Inativo</span>
                    <span v-else-if="con.status == 'active'" class="badge badge-success p-2 font-size-13 text-uppercase">Ativo</span>
                    <span v-else-if="con.status == 'closed'" class="badge badge-dark p-2 font-size-13 text-uppercase">Encerrado</span>
                    <span v-else-if="con.status == 'deleted'" class="badge badge-danger p-2 font-size-13 text-uppercase">Cancelado</span>
                  </div>
                  <div class="col-md-4 contract-status mb-2">
                    <small>ASSINTURA</small><br />
                    <span
                      v-if="con.sign == 'pending'"
                      class="badge badge-danger p-2 font-size-13 text-uppercase"
                      >Pendente</span
                    >
                    <span
                      v-else-if="con.sign == 'signed'"
                      class="badge badge-success p-2 font-size-13 text-uppercase"
                      >Ativo</span
                    >
                    <span
                      v-else-if="con.sign == 'refused'"
                      class="badge badge-danger p-2 font-size-13 text-uppercase"
                      >Cancelado</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.contract {
  border-left: solid 5px #010101;
}
.contract-total {
  line-height: 14px;
}

.table-profit {
  line-height: 15px;
}
.table-profit th,
.table-profit td {
  white-space: nowrap;
}
.table-year th {
  background: #BEBFC1;
}
.table-month th {
  background: #D2D3D4;
}

.divisor {
  position: relative;
  text-align: center;
}
.divisor span {
  display: inline-block;
  padding: 0 5px;
  background: #fff;
  font-size: 10px;
  letter-spacing: 2px;
  color: #888;
}
.divisor:before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 50%;
  height: 1px;
  background: #ddd;
}
</style>